import moment from 'moment';
import Swal from 'sweetalert2'
export default {
    name: 'EditUsersProfile',    
    created() {
        this.loginDetails = localStorage.getItem('currentUser-client');
        if (this.loginDetails != "null" && this.loginDetails != null && this.loginDetails != "" && this.loginDetails != "[object Object]")
        {
            this.loginDetails = JSON.parse(this.loginDetails);
            this.ruleForm.LoginName = this.loginDetails.loginName;
            this.ruleForm.FullName = this.loginDetails.name;
            this.ruleForm.UserType = this.loginDetails.userType;
            if (this.loginDetails.userType == 40) {
                this.ruleForm.FirstName = this.loginDetails.firstName;
                this.ruleForm.FatherName = this.loginDetails.fatherName;
                this.ruleForm.GrandFateherName = this.loginDetails.grandFateherName;
                this.ruleForm.SirName = this.loginDetails.sirName;
            }
            this.ruleForm.Email = this.loginDetails.email;
            this.ruleForm.Gender = this.loginDetails.gender;
            this.ruleForm.Phone = this.loginDetails.phone;
            this.ruleForm.ExtraPhone = this.loginDetails.extraPhone;
            this.ruleForm.DateOfBirth = this.loginDetails.dateOfBirth;
            this.ruleForm.ImagePath = this.loginDetails.imagePath;
            this.ruleForm.AboutMe = this.loginDetails.aboutMe;
            
        } else {
            window.location.href = "/Login";
        }
     
    },
    data() {  
       
        return {


            ruleForm: {
                FullName: '',
                FirstName: '',
                FatherName: '',
                GrandFateherName: '',
                SirName: '',
                LoginName: '',
                Email: '',
                Gender: '',
                DateOfBirth: '',
                ImagePath: '',
                ImageName: '',
                fileBase64: '',
                Phone: '',
                ExtraPhone: '',
                UserType: '',
                CreatedOn: '',
                Password: '',
                NewPassword: '',
                ConfirmPassword: '',
                AboutMe: '',
                
            },
            rules: {
                FullName: this.$helper.Required('الاسم '),
                FirstName1: this.$helper.Required('الاسم '),
                FatherName: this.$helper.Required('الاسم '),
                GrandFateherName: this.$helper.Required('الاسم '),
                SirName: this.$helper.Required('الاسم '),
                LoginName: this.$helper.Required('الاسم '),
                Email: this.$helper.Required('البريد اللإلكتروني '),
                Phone: this.$helper.Required('رقم الهاتف '),
                Password: this.$helper.Required('كلمة المرور '),
                NewPassword: this.$helper.Required('كلمة المرور الجديدة  '),
                ConfirmPassword: this.$helper.Required('تأكيد كلمة المرور  '),
                AboutMe: this.$helper.Required('النبذة الشخصية  '),
            },

        }
    },

    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format('MMMM Do YYYY');
        }
    },

    methods: {



        SelectAttachment(file) {
            let str = file.raw.type;
            str = str.substring(0, 5);

            if (str != "image") {
                this.$helper.ShowMessage('error', 'خطأ بالعملية', 'الرجاء التأكد من نوع الملف');
            }

            var $this = this;
            var reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = function () {
                $this.ruleForm.ImageName = file.raw.name;
                $this.ruleForm.fileBase64 = reader.result;
            };


            this.$http.ChangeProfilePicture(this.ruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    setTimeout(() => window.location.href = "/Login", 1000);
                    
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بالعملية', err.response.data);
                });

        },

        submitPersonalInfoForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.$blockUI.Start();
                    this.$http.EditPersonalInfo(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            //setTimeout(() => window.location.href = "/Login", 1000);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بالعملية', err.response.data);
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        submitContactForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.$blockUI.Start();
                    this.$http.EditContactInfo(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            //setTimeout(() => window.location.href = "/Login", 1000);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بالعملية', err.response.data);
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        submitPasswordForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    if (this.ruleForm.NewPassword != this.ruleForm.ConfirmPassword)
                        this.$helper.ShowMessage('error', 'خطأ بالعملية', 'الرجاء التأكد من تطابق كلمات المرور');

                    this.$blockUI.Start();
                    this.$http.ChangePassword(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            //setTimeout(() => window.location.href = "/Login", 1000);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بالعملية', err.response.data);
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        submitAboutUsForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.$blockUI.Start();
                    this.$http.AboutMeInfo(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            //setTimeout(() => window.location.href = "/Login", 1000);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بالعملية', err.response.data);
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },


        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.EditUsersProfile(formName);
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        EditUsersProfile() {

            this.$blockUI.Start();
            this.$http.EditUsersProfile(this.ruleForm)
                .then(response => {
                    //this.resetForm(formName);
                    this.loginDetails.email = this.ruleForm.Email;
                    this.loginDetails.phone = this.ruleForm.Phone;
                    this.loginDetails.loginName = this.ruleForm.LoginName;
                    this.loginDetails.fullName = this.ruleForm.FullName;
                    this.loginDetails.gender = this.ruleForm.Gender;
                    this.loginDetails.dateOfBirth = this.ruleForm.DateOfBirth;
                    localStorage.setItem('currentUser-admin', JSON.stringify(this.loginDetails));
                    this.$blockUI.Stop();
                    Swal.fire({
                        icon: 'success',
                        title: '..نجـاح العملية',
                        // text: '<strong>Something went wrong!</strong>',
                        html:
                            response.data,
                        // showCloseButton: true,
                        showCancelButton: false,
                        //confirmButtonText: `حـفظ`,
                        //denyButtonText: `مواق`,
                    }).then(() => {

                    });
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$notify({
                        title: 'خطأ بعملية الاضافة',
                        dangerouslyUseHTMLString: true,
                        type: 'error',
                        message: err.response.data
                    });
                });
        },

















        validPhone: function (Phone) {

            var mobileRegex = /^09[1|2|3|4|5][0-9]{7}$/i;

            return mobileRegex.test(Phone);
        },
        FileChanged(e) {
            var files = e.target.files;

            if (files.length <= 0) {
                return;
            }

            if (files[0].type !== 'image/jpeg' && files[0].type !== 'image/png') {
                this.$message({
                    type: 'error',
                    message: 'عفوا يجب انت تكون الصورة من نوع JPG ,PNG'
                });
                this.photo = null;
                return;
            }

            var $this = this;

            var reader = new FileReader();
            reader.onload = function () {
                $this.photo = reader.result;
                $this.UploadImage();
            };
            reader.onerror = function () {
                $this.photo = null;
            };
            reader.readAsDataURL(files[0]);
        },

        UploadImage() {
           
            this.$blockUI.Start();
            var obj = {
                Photo: this.photo,
                UserId: this.loginDetails.userId
            };
            this.$http.UploadImage(obj)
                .then(response => {
                    this.$blockUI.Stop();
                    this.$message({
                        type: 'info',
                        message: response.data
                    });

                    setTimeout(() =>
                        window.location.href = '/'
                        , 500);

                })
                .catch((err) => {
                    this.$blockUI.Stop(err);
                    this.pages = 0;
                });
        },
        validEmail: function (email) {
            var re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validLoginName: function (LoginName) {
            var login = /^[a-zA-Z]{0,40}$/;
            return login.test(LoginName);
        },
        //Save() {
        //    if (!this.form.LoginName) {
        //        this.$message({
        //            type: 'error',
        //            message: 'الـرجاء إدخال اسم الدخول'
        //        });
        //        return;
        //    } else if (!this.validLoginName(this.form.LoginName)) {
        //        this.$message({
        //            type: 'error',
        //            message: 'الرجاء إدخال اسم الدخول بطريقه صحيحه '
        //        });
        //        return;
        //    }
        //    if (!this.form.Email) {
        //        this.$message({
        //            type: 'error',
        //            message: 'الرجاء إدخال البريد الإلكتروني '
        //        });
        //        return;
        //    } else if (!this.validEmail(this.form.Email)) {
        //        this.$message({
        //            type: 'error',
        //            message: 'الرجاء إدخال البريد الإلكتروني بطريقه صحيحه '
        //        });
        //        return;
        //    }

        //    if (!this.form.Gender) {
        //        this.$message({
        //            type: 'error',
        //            message: 'الرجاء إختيار الجنس '
        //        });
        //        return;
        //    }

        //    if (!this.form.DateOfBirth) {
        //        this.$message({
        //            type: 'error',
        //            message: 'الرجاء إختيار تاريخ الميلاد '
        //        });
        //        return;
        //    }
        //    if (!this.form.Phone) {
        //        this.$message({
        //            type: 'error',
        //            message: 'الرجاء رقم الهاتف '
        //        });
        //        return;
        //    } else if (!this.validPhone(this.form.Phone)) {
        //        this.$message({
        //            type: 'error',
        //            message: 'الرجاء إدخال رقم الهاتف  بطريقه صحيحه '
        //        });
        //        return;
        //    }

        //    this.form2.Phone = this.form.Phone
        //    this.form2.Email = this.form.Email;
        //    this.form2.LoginName = this.form.LoginName;
        //    this.form2.FullName = this.form.FullName;
        //    this.form2.Gender = this.form.Gender;
        //    this.form2.DateOfBirth = this.form.DateOfBirth;
       
        //    this.$http.EditUsersProfile(this.form2)
        //        .then(response => {
        //            this.loginDetails.email = this.form2.Email;
        //            this.loginDetails.phone = this.form2.Phone;
        //            this.loginDetails.loginName = this.form2.LoginName;
        //            this.loginDetails.fullName = this.form2.FullName;
        //            this.loginDetails.gender = this.form2.Gender;
        //            this.loginDetails.dateOfBirth = this.form2.DateOfBirth;
        //            localStorage.setItem('currentUser-admin', JSON.stringify(this.loginDetails));
        //            this.$message({
        //                type: 'info',
        //                message: response.data
        //            });
                  
        //        })
        //        .catch((err) => {
        //            this.$message({
        //                type: 'error',
        //                message: err.response.data
        //            });
        //        });
        //},

    }
}
