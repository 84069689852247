import HelperMixin from '../../../Shared/HelperMixin.vue'
export default {
    name: 'AppHeader',
    mixins: [HelperMixin],
    async created() {
        const loginDetails = await this.CheckLoginStatus();
        if (loginDetails) {
            if (this.loginDetails.userType != 1
                && this.loginDetails.userType != 3)
                this.logout();

        }
    },
    data() {
        return {  
            Info: [],
            TempInfo: [],
            Count: 0,
        };
    },
  
    methods: {

        //href(url) {
        //    this.$router.push(url);
        //},
        href(url, id) {
            this.$router.push(url);
            for (var i = 0; i < 20; i++) {
                if (i == id) {
                    this.$set(this.menuFlag, id, '');
                } else {
                    this.$set(this.menuFlag, i, '');
                }
            }
        },
        Logout() {
            window.location.href = "/Login";
        },

       
        OpenMenuByToggle() {

            var root = document.getElementsByTagName("main")[0];

            if (document.getElementById("sidenav-main").classList.contains('active')) {
                document.getElementById("sidenav-main").classList.remove('active');
                //document.getElementById("sidenav-main").style.maxWidth = "6rem !important";
                document.getElementById("sidenav-main").style = "max-width: 6rem!important;";
                document.getElementById("sidenav-main").style = "max-width: 6rem!important;";
                root.style = "margin-right: 7.5rem;";

                
            } else {
                document.getElementById("sidenav-main").classList.add('active');
                document.getElementById("sidenav-main").style = "max-width: 15.625rem !important;";
                root.style = "margin-right: 17.125rem;";
                
            }


             // '0' to assign the first (and only `HTML` tag)
            if (root.getAttribute("class") == "rtl sidebar-mini rtl-active") {
                root.setAttribute("class", "rtl rtl-active");
            } else {
                root.setAttribute("class", "rtl sidebar-mini rtl-active");
            }
        },

        playSound() {
            const audio = new Audio("windows8_email_notif.mp3");
            audio.play();
        }
    }    
}
