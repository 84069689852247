////import Swal from 'sweetalert2'
import moment from 'moment';
import HelperMixin from '../../../../Shared/HelperMixin.vue'

export default {
    name: 'DistributorsPackages',
    mixins: [HelperMixin],
    async created() {
        await this.CheckLoginStatus();
        if (this.loginDetails.userType != 50)
            this.logout();
        this.GetInfo();
    },
    components: {
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
      
        return {
            Info: [],
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,
        };
    },
    methods: {
        GetInfo(pageNo) {
            this.Info = [];
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            this.$blockUI.Start();
            this.$http.GetDistributorsPackages(this.pageNo, this.pageSize)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

    }
}
