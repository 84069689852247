////import Swal from 'sweetalert2';
import moment from 'moment';
import flatPickr from "vue-flatpickr-component";
import HelperMixin from '../../../Shared/HelperMixin.vue'
export default {
    name: 'Subscriptions',
    mixins: [HelperMixin],
    async created() {
        await this.CheckLoginStatus();
        if (this.loginDetails.userType >10)
            this.logout();

        await this.GetAcademicLevels();
        await this.GetSupjectName();
        this.GetInfo();

    },
    components: {
        flatPickr,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
        return {
            pageNo: 1,
            pageSize: 10, 
            pages: 0,
            state: 0,
            Info: [],
            AcademicLevelId: '',
            AcademicSpecializationId: '',
            SubjectId: '',
            CourseId: '',
            StudentPhones: [],
            studentId: '',
            SubscriptionType: '',


        };
    },
    methods: {


        async GetAcademicSpecializationName() {
            this.AcademicSpecializationId = '';
                await this.GetAcademicSpecialization(this.AcademicLevelId);
            this.GetInfo();
        },

        async GetSupjectName() {
            this.SubjectId = '',
                await this.GetSupjects()
            this.GetInfo();
        },

        async GetCourseNameByAcademicSpecialization() {
            this.CourseId = '',
                await this.GetCourseName(this.AcademicSpecializationId, this.SubjectId);
            this.GetInfo();
        },

        GetStudentName() {
            this.StudentPhones = [];
            let code = "";
            if (document.getElementById('selectInputName') != null) {

                if (document.getElementById('selectInputName').value == null || document.getElementById('selectInputName').value == '')
                    return;

                code = document.getElementById('selectInputName').value;
            }

            if (code.length <= 3)
                return;

            this.$blockUI.Start();
            this.$http.GetStudentByStudentName(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.StudentPhones = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    //console.error(err);
                });
        },

        GetName() {
            this.StudentPhones = [];
            let code = "";
            if (document.getElementById('selectInput') != null) {

                if (document.getElementById('selectInput').value == null || document.getElementById('selectInput').value == '')
                    return;

                code = document.getElementById('selectInput').value;
            }

            if (code.length <= 4)
                return;

            this.$blockUI.Start();
            this.$http.GetStudentPhone(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.StudentPhones = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    //console.error(err);
                });
        },

        Refresh() {
            this.studentId = null;
            this.AcademicLevelId = '',
            this.AcademicSpecializationId = '',
            this.SubjectId = '',
            this.CourseId = '',
            this.SubscriptionType = '',
            this.GetInfo();
        },




        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            this.$blockUI.Start();
            this.$http.GetStudentsSubscriptions(this.pageNo, this.pageSize,
                this.AcademicLevelId,
                this.AcademicSpecializationId,
                this.SubjectId,
                this.CourseId,
                this.studentId,
                this.SubscriptionType,
            )
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },


        

        //OpenAddItemDilog() {
        //    this.state = 1;
        //},

        //async GetMunicipalitiesName() {
        //    this.ruleForm.MunicipalitId = '',
        //    await this.GetMunicipalities(this.ruleForm.CityId)
        //},

        //async GetLocationsName() {
        //    this.ruleForm.LocationId = '',
        //    await this.GetLocations(this.ruleForm.MunicipalityId)
        //},

        //async GetSupjectName() {
        //    this.StudentCourseruleForm.SubjectId = '',
        //        await this.GetSupjects()
        //},

        //async GetAcademicSpecializationName() {
        //    this.ruleForm.AcademicSpecializationId = '',
        //        await this.GetAcademicSpecialization(this.ruleForm.AcademicLevelId)
        //},

        //async GetCourseNameByAcademicSpecialization() {
        //    this.StudentCourseruleForm.CourseId = '',
        //        await this.GetCourseName(this.ruleForm.AcademicSpecializationId, this.StudentCourseruleForm.SubjectId)
        //},

        //submitForm(formName,type) {
        //    this.$refs[formName].validate((valid) => {
        //        if (valid) {
        //            if (type == 1) {
        //                this.Add(formName);
        //            } else {
        //                this.Edit(formName);
        //            }
                    
        //        } else {
        //            this.$helper.showSwal('warning');
        //            return false;
        //        }
        //    });
        //},

        //resetForm(formName) {
        //    this.$refs[formName].resetFields();
        //},

        //Add(formName) {
        //    this.ruleForm.Id = Number(this.ruleForm.Id);
        //    this.ruleForm.Value = Number(this.ruleForm.Value);
        //    this.$blockUI.Start();
        //    this.$http.AddStuent(this.ruleForm)
        //        .then(response => {
        //            this.$blockUI.Stop();
        //            this.resetForm(formName);
        //            this.GetInfo();
        //            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
        //            this.state = 0;
        //        })
        //        .catch((err) => {
        //            this.$blockUI.Stop();
        //            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
        //        });
        //},

        

        //OpenEdit(item) {
        //    this.state = 2;
        //    this.selectedItem = item;
        //    this.ruleForm.Id = item.id;
        //    this.ruleForm.FirstName = item.firstName;
        //    this.ruleForm.FatherName = item.fatherName;
        //    this.ruleForm.GrandFatherName = item.grandFatherName;
        //    this.ruleForm.SirName = item.sirName;
            
        //    this.ruleForm.LoginName = item.userInfo.loginName;
        //    this.ruleForm.Gender = item.userInfo.gender;
        //    this.ruleForm.BirthDate = item.userInfo.birthDate;

        //    this.ruleForm.Phone = item.userInfo.phone;
        //    this.ruleForm.ExtraPhone = item.userInfo.extraPhone;
        //    this.ruleForm.BirthDate = item.userInfo.birthDate;
        //    this.ruleForm.Email = item.userInfo.email;
        //},

        //Edit(formName) {
        //    this.$blockUI.Start();
        //    this.ruleForm.AcademicLevelId =0;
        //    this.ruleForm.AcademicSpecializationId = 0;
        //    this.ruleForm.LocationId = 0;
        //    this.ruleForm.AcademicLevelId = Number(this.ruleForm.AcademicLevelId);
        //    this.ruleForm.AcademicSpecializationId = Number(this.ruleForm.AcademicSpecializationId);
        //    this.ruleForm.LocationId = Number(this.ruleForm.LocationId);
        //    this.ruleForm.AcademicLevelId=Number()
        //    this.$http.EditStudent(this.ruleForm)
        //        .then(response => {
        //            this.$blockUI.Stop();
        //            this.resetForm(formName);
        //            this.GetInfo();
        //            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
        //            this.state = 0;
        //        })
        //        .catch((err) => {
        //            this.$blockUI.Stop();
        //            this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
        //        });
        //},
        
        //Delete(id) {
        //    Swal.fire({
        //        title: 'هـل انت متأكد من عملية الحذف ؟',
        //        showDenyButton: true,
        //        showCancelButton: false,
        //        confirmButtonText: `تأكيد العملية`,
        //        denyButtonText: `الغاء العملية`,
        //    }).then((result) => {
        //        if (result.isConfirmed) {
        //            this.$blockUI.Start();
        //            this.$http.DeleteStudent(id)
        //                .then(response => {
        //                    this.$blockUI.Stop();
        //                    this.GetInfo();
        //                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
        //                })
        //                .catch((err) => {
        //                    this.$blockUI.Stop();
        //                    this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
        //                });
        //            return;
        //        }
        //    })
        //},

        

    }
}
