import Swal from 'sweetalert2'
import moment from 'moment';
import HelperMixin from '../../Shared/HelperMixin.vue'
export default {

    name: 'Locations',
    modifiedBy: [],
    mixins: [HelperMixin],
    async created() {
        await this.CheckLoginStatus();
        if (this.loginDetails.userType != 1)
            this.logout();

        this.GetInfo();
        this.GetCities();
        this. GetallMunicipalities() 
    },
    components: {
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
        return {

            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,
            Info: [],
            Cities: [],
            location:[],
            MunicipalityId: [],
            Locations: [],
            EditDialog: false,
            AddDiloag: false,
            ruleForm: {  
                CityId: '',
                Name: '',
                MunicipalityId: '',    
            },  
            rules: {
                CityId: this.$helper.Required(),
                MunicipalityId: this.$helper.Required(),
                Name: this.$helper.DynamicArabicEnterRequired(' إسم المنطقة'),
            },

        };
    },
    watch: {
        'ruleForm.CityId'(){
            this.GetallMunicipalitiess();
            this.ruleForm.MunicipalityId = '';
}
    },

    methods: {

        OpenAddItem() {
            this.AddDiloag = true;
            this.Clear();
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.AddLocations(formName);
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
      
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        AddLocations(formName) {      
            this.$blockUI.Start();
            this.$http.AddLocations(this.ruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.AddDiloag = false;
                    this.GetInfo();
                    this.Clear();
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                });
        },

        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            this.$blockUI.Start();
            this.$http.GetLocations(this.pageNo, this.pageSize)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },
        GetCities() {
            
            this.CityId = [];
            this.CityId = '';
            this.$blockUI.Start();
            this.$http.GetCities1(this.ruleForm.MunicipalityId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Cities = response.data.info;
                })
                .catch (() => {
                    this.$blockUI.Stop();
                    
                })
        },
       
        GetallMunicipalities(id = '') {
            this.Municipalities = [];
            this.MunicipalityId = '';
            this.$blockUI.Start();
            this.$http.GetallMunicipalities(this.ruleForm.CityId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Municipalities = response.data.info;
                    this.ruleForm.MunicipalityId = id;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                })
        },
        GetallMunicipalitiess() {
            this.Municipalities = [];
            this.MunicipalityId = '';
            this.$blockUI.Start();
            this.$http.GetallMunicipalitiess(this.ruleForm.CityId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Municipalities = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                })
        },

        Deleteitem(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteLocations(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                showCancelButton: true,
                                denyButtonText: `خروج`,
                            }).then(() => {
                                this.$blockUI.Stop();
                                this.GetInfo();
                            });


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية الحذف',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;

                }
            })
        },

        ChangeStatus(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية تغير الحالة ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.ChangeStatusLocations(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                showCancelButton: true,
                                denyButtonText: `خروج`,
                            }).then(() => {
                                this.$blockUI.Stop();
                                this.GetInfo();
                            });


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية تغير الحالة',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;

                }
            })
        },

        EditItem(item) {
            this.ruleForm.Id = item.id;
            this.ruleForm.CityId = item.cityId;
       /*     this.ruleForm.MunicipalityId = item.municipalityId;*/
            this.ruleForm.Name = item.name;
            this.GetallMunicipalities(item.municipalityId);
            this.EditDialog = true;
        },

        SubmitEditForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Edit(formName);
                } else {
                    return false;
                }
            });
        },

        Edit(formName) {
            this.$blockUI.Start();
            this.$http.EditLocations(this.ruleForm)
                .then(response => {
                    this.resetForm(formName);
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('succsess', 'نجاح العملية', response.data);
                    this.EditDialog = false;
                    this.GetInfo();
                    this.Clear();

                })

                .catch((err) => {

                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                });

        },

        Clear() {
            this.ruleForm.Name = '';
            this.ruleForm.CityId = '';
            this.ruleForm.MunicipalityId = '';
        },
    }
}
