import Swal from 'sweetalert2'
import moment from 'moment';
import flatPickr from "vue-flatpickr-component";
import HelperMixin from '../../Shared/HelperMixin.vue'
export default {
    

    name: 'InstructorsLicense',
    modifiedBy: [],
    mixins: [HelperMixin],
    async created() {

        await this.CheckLoginStatus();
        if (this.loginDetails.userType != 1)
            this.logout();

        this.GetInfo();
        this.GetInstructors();
        this.GetSubscriptions();
        this.GetValues()

        
    },
    refresh() {
        this.MunicipalitiesId = '';
        this.GetInfo();
    },
    components: {
        flatPickr,
    },

    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
        return {
            config: {
                allowInput: true,
            },

            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,
            Info: [],
            InstructorsId: [],
            SubscriptionId: [],
            Value: [],
            EditDialog: false,
            AddDiloag: false,
            ruleForm: {               
                InstructorsId:'',
                SubscriptionId:'',
                Value:'',
                From: '',

            },
            rules: {
                InstructorsId: this.$helper.Required(),
                SubscriptionId: this.$helper.Required(),
                From: this.$helper.Required(),
                Value: this.$helper.NumberOnlyRequired(),
                
            },

        };
    },
    //watch: {
    //    'ruleForm.SubscriptionId'() {
    //        this.GetValues();
    //        this.ruleForm.Value = '';
    //       /* this.GetValue(item.value);*/

    //    }
    //},
    methods: {

        OpenAddItem() {
            this.AddDiloag = true;
            this.Clear();
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.AddInstructorsLicense(formName);
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        AddInstructorsLicense(formName) {      
            this.ruleForm.Value = Number(this.ruleForm.Value);
            this.$blockUI.Start();
            this.$http.AddInstructorsLicense(this.ruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.AddDiloag = false;
                    this.GetInfo();
                    this.Clear();
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                });
        },

        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            this.$blockUI.Start();
            this.$http.GetInstructorsLicense(this.pageNo, this.pageSize)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },
        GetInstructors() {
            this.InstructorsId = '';
            this.InstructorsId = [];
            this.$blockUI.Start();
            this.$http.GetInstructor()
                .then(response => {
                    this.$blockUI.Stop();
                    this.Instructors = response.data.info;
                    
                })
                .catch (() => {
                    this.$blockUI.Stop();
                })
        },
        GetSubscriptions() {
            this.GetValues();
            this.SubscriptionId = '';
            this.SubscriptionId = [];
            this.$blockUI.Start();
            this.$http.GetSubscription(this.ruleForm.Value)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Subscriptions = response.data.info;
                    //this.ruleForm.SubscriptionId = response.data.info.subscriptionsName;

                })
                .catch(() => {
                    this.$blockUI.Stop();
                })
        },
        GetValues() {
           
            this.Value = '';
            this.Value= [];

            this.$blockUI.Start();
            this.$http.GetValue(this.ruleForm.SubscriptionId)
                .then(response => {
                    this.$blockUI.Stop();
                    /*console.log()*/
                    //this.Value = response.data.info.value;
                    this.ruleForm.Value = response.data.info.value;
                 
      
                })
                .catch(() => {
                    this.$blockUI.Stop();
                })
        },
 



        Deleteitem(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteInstructorsLicense(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                showCancelButton: true,
                                denyButtonText: `خروج`,
                            }).then(() => {
                                this.$blockUI.Stop();
                                this.GetInfo();
                            });


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية الحذف',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;

                }
            })
        },

        EditItem(item) {
            this.ruleForm.Id = item.id;
            this.ruleForm.InstructorsId = item.instructors.id;
            this.ruleForm.SubscriptionId = item.subscription.id;
            this.ruleForm.Value = item.value;
            this.ruleForm.From = item.from;
            this.EditDialog = true;
        },
    

        SubmitEditForm(formName) {


            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Edit(formName);
                } else {
                    return false;
                }
            });
        },
        

        Edit(formName) {
            this.$blockUI.Start();
            this.$http.EditInstructorsLicense(this.ruleForm)
                .then(response => {
                    this.resetForm(formName);
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('succsess', 'نجاح العملية', response.data);
                    this.EditDialog = false;
                    this.GetInfo();
                    this.Clear();

                })

                .catch((err) => {

                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                });

        },

        Clear() {
            this.ruleForm.InstructorsId = '';
            this.ruleForm.SubscriptionId = '';
            this.ruleForm.Value = '';
            this.ruleForm.From = '';

        },
    }
}
