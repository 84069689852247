import Swal from 'sweetalert2';
import moment from 'moment';
import flatPickr from "vue-flatpickr-component";
import HelperMixin from '../../../Shared/HelperMixin.vue'
export default {
    name: 'Requests',
    mixins: [HelperMixin],
    async created() {

        await this.CheckLoginStatus();
        if (this.loginDetails.userType != 1)
            this.logout();

        //await this.GetCities();
        //await this.GetAcademicLevels();
        //await this.GetPaymentMethods();
        //await this.CheckLoginStatus();
        //await this.GetSupjectName();
        this.GetInfo();

    },
    components: {
        flatPickr,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
        return {
            pageNo: 1,
            pageSize: 10, 
            pages: 0,
            state: 0,
            Info: [],
            Diloag: false,
            Resone: {
                RejectResone: '',

            },  
        };

    },
    methods: {

        OpenDiloag(item) {
            this.Diloag = true;
            this.Resone.Id = item.id;
        },
        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            this.$blockUI.Start();
            this.$http.GetInstructorsRequests(this.pageNo, this.pageSize)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

     
        Accept(Id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية القبول ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.AcceptedRequests(Id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.$helper.ShowMessage('success', 'تم قبول الطلب', response.data);                 

                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية القبول', err.response.data);

                        });
                    return;
                }
            })
        },
        SubmitReject(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Reject(formName);

                } else {
                    return false;
                }
            });
        },
        Reject() {

            this.$blockUI.Start();
            this.$http.RejectedRequests(this.Resone)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Diloag = false;
                    this.GetInfo();
                    this.$helper.ShowMessage('success', 'تم رفض الطلب', response.data);                 
                    this.Resone.RejectResone = '';
                })

                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية الرفض', err.response.data);

                });

        },

        
        

    }
}
