import Swal from 'sweetalert2'
import moment from 'moment';
import HelperMixin from '../../Shared/HelperMixin.vue'
export default {
    name: 'Cities',
    modifiedBy: [],
    mixins: [HelperMixin],
    async created() {
        await this.CheckLoginStatus();
        if (this.loginDetails.userType != 1)
            this.logout();
        this.GetInfo();
    },
    components: {
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
        return {

            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,
            Info: [],

            EditDialog: false,
            AddDiloag: false,
            ruleForm: {
               /* Ud:'',*/
                Name: '',
            },

            rules: {
                Name: this.$helper.DynamicArabicEnterRequired(' اسم المدينة'),
            },

        };
    },
    methods: {

        OpenAddItem() {
            this.AddDiloag = true;
            this.Clear();
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Add(formName);
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        Add(formName) {
            this.ruleForm.id = 0;
            this.$blockUI.Start();
            this.$http.Add(this.ruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.AddDiloag = false;
                    this.GetInfo();
                    this.Clear();
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                });
        },

        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            this.$blockUI.Start();
            this.$http.Get(this.pageNo, this.pageSize)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        Deleteitem(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.Deletecities(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                showCancelButton: true,
                                //confirmButtonText: `حـفظ`,
                                denyButtonText: `خروج`,
                            }).then(() => {
                                this.$blockUI.Stop();
                                this.GetInfo();
                            });


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية الحذف',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;

                }
            })
        },

        ChangeStatus(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية تغير الحالة ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.ChangeStatusCities(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                showCancelButton: true,
                                //confirmButtonText: `حـفظ`,
                                denyButtonText: `خروج`,
                            }).then(() => {
                                this.$blockUI.Stop();
                                this.GetInfo();
                            });


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية تغير الحالة',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;

                }
            })
        },

        EditItem(item) {
            this.ruleForm.Id = item.id;
            this.ruleForm.Name = item.name;
            this.EditDialog = true;
        },

        SubmitEditForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Edit(formName);
                } else {
                    return false;
                }
            });
        },

        Edit(formName) {
            this.$blockUI.Start();
            this.$http.Edit(this.ruleForm)
                .then(response => {
                    this.resetForm(formName);
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('succsess', 'نجاح العملية', response.data);
                    this.EditDialog = false;
                    this.GetInfo();
                    this.Clear();

                })

                .catch((err) => {

                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                });

        },

        Clear() {
            this.ruleForm.Name = '';
        },
    }
}
