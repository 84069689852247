import LectureAttashments from './LectureAttashments/LectureAttashments.vue';
import Swal from 'sweetalert2'
import moment from 'moment';
import HelperMixin from '../../../../Shared/HelperMixin.vue'
export default {

    name: 'Lectures',
    modifiedBy: [],
    mixins: [HelperMixin],
     created() {
        this.GetInfo();
        this.GetCities();
        this.GetallMunicipalities() 
         this.ruleForm.ShapterId =this.$parent.EditUsersObj.id;

    },

    components: {
        'LectureAttashments': LectureAttashments,

    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
        return {

            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,
            Info: [],
            Cities: [],
            location:[],
            MunicipalityId: [],
            Locations: [],
            CourseId: [],
            imageUrl: '',
            ruleForm: {  
                EnglishName: '',
                Name: '',
                Descriptions: '', 
                Number: '', 
                ShapterId: '',
            },  
            rules: {
                CityId: this.$helper.Required(),
                MunicipalityId: this.$helper.Required(),
                Name: this.$helper.DynamicArabicEnterRequired(' اسم البلدية'),
            },

        };
    },
    watch: {
        'ruleForm.CityId'(){
            this.GetallMunicipalitiess();
            this.ruleForm.MunicipalityId = '';
}
    },

    methods: {
        Images22(e) {
            //console.log(e)
            //    var $this = this;
            //    var reader = new FileReader();
            //    reader.readAsDataURL(file.raw);
            //    reader.onload = function () {
            //        var obj =
            //        {
            //            fileName: file.raw.name,
            //            fileBase64: reader.result,
            //        };
            //        $this.ruleForm.fileList.push(obj);
            //    }
            //    //console.log($this.ruleForm.attashFile);
            //},
            var files = e.raw;
            this.ruleForm.fileBase64 = btoa(files)
        },
        
        //Image(e) {
        //    var files = e.target.files;
        //    if (files.length <= 0) {
        //        return;
        //    }

        //    var $this = this;
        //    var reader = new FileReader();
        //    reader.onload = function () {

        //        this.ruleForm.fileBase64 = reader.result;
        //        //this.ruleForm.fileType = files[0].name;
        //        //this.ruleForm.Name = files[0].name;


        //    };
        //    reader.onerror = function () {
        //        $this.photo = null;
        //    };
        //    reader.readAsDataURL(files[0]);
        //},
        Attashments(User) {
            this.state = 3;
            //this.GetInfo1();
            //this.selectedItem = item;
            this.EditUsersObj = User;
            //this.CourseForm.Id = item.id;
            //this.Obj = Shapters;
        },
        back() {
            this.$parent.state = 0;
            this.$parent.GetInfo();
        },
        OpenAddItem(item) {
            this.state = 1;
            
            this.selectedItem = item;
            this.ruleForm.ShapterId = this.$parent.EditUsersObj.id;
            this.GetInfo();
            this.Clear();
            //this.selectedItem = item;
            //alert(this.$route.params.id)
            //this.ruleForm.CourseId = item.id
           

            
            /*this.Clear();*/
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Add(formName);
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
      
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        Add(formName) {     
            this.ruleForm.ShapterId = Number(this.ruleForm.ShapterId);
            this.ruleForm.Number = Number(this.ruleForm.Number);

            this.$blockUI.Start();
            this.$http.AddLectures(this.ruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.state = 0;
                    this.GetInfo();
                    this.Clear();
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                });
        },

        GetInfo(pageNo) {
            this.ruleForm.ShapterId = this.$parent.EditUsersObj.id;
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            this.$blockUI.Start();
            this.$http.GetLectures(this.pageNo, this.pageSize, this.ruleForm.ShapterId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },
        

        Deleteitem(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteLectures(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                showCancelButton: true,
                                denyButtonText: `خروج`,
                            }).then(() => {
                                this.$blockUI.Stop();
                                this.GetInfo();
                            });


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية الحذف',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;

                }
            })
        },

        ChangeStatus(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية تغير الحالة ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.ChangeStatusLectures(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                showCancelButton: true,
                                denyButtonText: `خروج`,
                            }).then(() => {
                                this.$blockUI.Stop();
                                this.GetInfo();
                            });


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية تغير الحالة',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;

                }
            })
        },

        EditItem(item) {
            this.ruleForm.Id = item.id;
            this.ruleForm.ShapterId = this.$parent.EditUsersObj.id;
            this.ruleForm.Descriptions = item.descriptions;
       /*     this.ruleForm.MunicipalityId = item.municipalityId;*/
            this.ruleForm.Name = item.name;
            this.ruleForm.EnglishName = item.englishName;
            this.ruleForm.Number = item.number;
            this.ruleForm.Rate = item.Rate;


            this.state = 2;
        },

        SubmitEditForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Edit(formName);
                } else {
                    return false;
                }
            });
        },

        Edit(formName) {
            this.$blockUI.Start();
            this.$http.EditLectures(this.ruleForm)
                .then(response => {
                    this.resetForm(formName);
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('succsess', 'نجاح العملية', response.data);
                    this.state = 0;
                    this.GetInfo();
                    this.Clear();

                })

                .catch((err) => {

                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                });

        },

        Clear() {
            this.ruleForm.Name = '';
            this.ruleForm.Descriptions = '';
            this.ruleForm.EnglishName = '';
            this.ruleForm.Rate = '';
            this.ruleForm.Number = '';
        },
    }
}
