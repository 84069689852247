////import Swal from 'sweetalert2'
import moment from 'moment';
import HelperMixin from '../../../Shared/HelperMixin.vue'

export default {
    name: 'AddUser',
    mixins: [HelperMixin],
    async created() {
        await this.CheckLoginStatus();
        if (this.loginDetails.userType != 1)
            this.logout();

        //this.GetInfo();
        
    },
    components: {
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format('MMMM Do YYYY');
        },
        momentTime: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format('DD/MM/YYYY || hh:mm a');
        }
    },
    data() {
        return {
            Info: '',
            Users: [],
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,


            SerialNumber:'',
        };
    },
    methods: {


        GetInfo() {
            this.Info = '';
            this.$blockUI.Start();
            this.$http.GetCardInfo(this.SerialNumber)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        


    }
}
