

import Vue from 'vue';
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import Vuetify from 'vuetify'
//import UploaderPlugin  from '@syncfusion/ej2-vue-inputs';
import locale from 'element-ui/lib/locale/lang/en'
import BlockUIService from './Shared/BlockUIService.js';
import App from './App.vue';
import Layout from './components/Layout/Layout.vue';
import Login from './components/Login/Login.vue';
import Home from './components/Home/Home.vue';
import DataService from './Shared/DataService';
import Helper from './Shared/Helper';
import Profile from './components/Users/EditUsersProfile/EditUsersProfile.vue'
import Users from './components/Users/Users.vue';
import AddUsers from './components/Users/AddUsers/AddUsers.vue';
import ChangePassword from './components/Users/ChangePassword/ChangePassword.vue';

import Students from './components/Students/Students.vue';
import StudentsSubscriptions from './components/Students/Subscriptions/Subscriptions.vue';
import StudentsSuspend from './components/Students/Suspend/Suspend.vue';
import ChangeRequests from './components/Students/ChangeRequests/ChangeRequests.vue';
import RegersterRequest from './components/Students/RegersterRequest/RegersterRequest.vue';


import Courses from './components/Courses/Courses.vue';

import Cities from './components/Cities/Cities.vue';
import Municipalities from './components/Municipalities/Municipalities.vue';
import Locations from './components/Locations/Locations.vue';
import Subscriptions from './components/Subscriptions/Subscriptions.vue';
import Instructors from './components/Instructors/Instructors.vue';
import InstructorsSuspend from './components/Instructors/Suspend/Suspend.vue';
import InstructorsRequests from './components/Instructors/Requests/Requests.vue';
import Trainingcenter from './components/Trainingcenter/Trainingcenter.vue';
//import Shapters from './components/Trainingcenter/Shapters/Shapters.vue';

import AcademicLevels from './components/AcademicLevels/AcademicLevels.vue';
import AcademicSpecialization from './components/AcademicLevels/AcademicSpecialization/AcademicSpecialization.vue';
import Subjects from './components/Subjects/Subjects.vue';


import InstructorsLicense from './components/InstructorsLicense/InstructorsLicense.vue';

import Applications from './components/Applications/Applications.vue';
import AddApplications from './components/Applications/Add/Add.vue';


import DailyRecharge from './components/Financial/DailyRecharge/DailyRecharge.vue';
import DailySubscriptions from './components/Financial/DailySubscriptions/DailySubscriptions.vue';


import ContactUs from './components/Settings/ContactUs/ContactUs.vue';



//VoucherCards
import VoucherCards from './components/VoucherCards/VoucherCards.vue';
import AddVoucherCards from './components/VoucherCards/Add/Add.vue';
import Cards from './components/VoucherCards/Cards/Cards.vue';
import EncryptFile from './components/VoucherCards/Distributors/EncryptFile/EncryptFile.vue';
import Distributors from './components/VoucherCards/Distributors/Distributors.vue';
import DistributorsPackages from './components/VoucherCards/Distributors/Packages/Packages.vue';








import InstructorsCourses from './components/InstructorsCourses/Courses.vue';




import VueEllipseProgress from 'vue-ellipse-progress';

Vue.use(VueEllipseProgress);

Vue.use(Vuetify)
Vue.use(VueI18n);
Vue.use(VueRouter);
//Vue.use(UploaderPlugin);
Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

Vue.prototype.$http = DataService;
Vue.prototype.$blockUI = BlockUIService;
Vue.prototype.$helper = Helper;


export const eventBus = new Vue();

//const i18n = new VueI18n({
//    locale: 'ar', // set locale
//    messages, // set locale messages
//})

const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    linkActiveClass: 'active',
    routes: [
        {
            path: '/Login',
            component: Login,
         
        },
         {
            path: '/',
            component: App,
            children: [
                {
                    path: '',
                    component: Layout,
                    children: [
                        { path: '', component: Home },
                        { path: 'Profile', component: Profile },
                        { path: 'Users', component: Users },
                        { path: 'Users/AddUsers', component: AddUsers },
                        { path: 'ChangePassword', component: ChangePassword },
                        { path: 'Cities', component: Cities },
                        { path: 'Municipalities', component: Municipalities },
                        { path: 'Locations', component: Locations },
                        { path: 'Subscriptions', component: Subscriptions },
                        { path: 'Instructors', component: Instructors },
                        { path: 'InstructorsSuspend', component: InstructorsSuspend },
                        { path: 'InstructorsRequests', component: InstructorsRequests },
                        { path: 'InstructorsLicense', component: InstructorsLicense },
                        { path: 'Trainingcenter', component: Trainingcenter },
                        //{ path: 'Trainingcenter/Shapters', component: Shapters },
                        
                        { path: 'AcademicLevels', component: AcademicLevels },
                        { path: 'AcademicSpecialization', component: AcademicSpecialization },
                        { path: 'Subjects', component: Subjects },

                        
                        { path: 'Students', component: Students },
                        { path: 'StudentsSubscriptions', component: StudentsSubscriptions },
                        { path: 'StudentsSuspend', component: StudentsSuspend },
                        { path: 'ChangeRequests', component: ChangeRequests },
                        { path: 'RegersterRequest', component: RegersterRequest },

                        { path: 'Courses', component: Courses },
                        
                        
                        { path: 'Applications', component: Applications },
                        { path: 'Applications/AddApplications', component: AddApplications },



                        { path: 'InstructorsCourses', component: InstructorsCourses },

                        { path: 'DailyRecharge', component: DailyRecharge },
                        { path: 'DailySubscriptions', component: DailySubscriptions },


                        { path: 'ContactUs', component: ContactUs },


                        //VoucherCards
                        { path: 'VoucherCards', component: VoucherCards },
                        { path: 'AddVoucherCards', component: AddVoucherCards },
                        { path: 'Cards', component: Cards },
                        { path: 'EncryptFile', component: EncryptFile },
                        { path: 'Distributors', component: Distributors },
                        { path: 'DistributorsPackages', component: DistributorsPackages },
                       
                    ]
                },
            ],
        }
    ]
});

Vue.filter('toUpperCase', function (value) {
    if (!value) return '';
    return value.toUpperCase();
});

new Vue({
    router,
    render: h => {
        return h(App);
    }
}).$mount('#cpanel-management');
