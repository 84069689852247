////import Swal from "sweetalert2";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import HelperMixin from '../../../Shared/HelperMixin.vue'

export default {
    name: "Add",
    mixins: [HelperMixin],
    async created() {
        await this.CheckLoginStatus();
        if (this.loginDetails.userType != 1)
            this.logout();
        await this.GetAllDistributors();
    },
    components: {
        flatPickr,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format("MMMM Do YYYY");
        },
    },
    data() {
        return {
            Offices: [],


            ruleForm: {
                Id: 0,
                UserId:'',
                Name: '',
                CardCount: '',
                CardAmount: '',

            },
            rules: {
                UserId: this.$helper.RequiredInput(' الموزع'),
                Name: this.$helper.RequiredInput(' اسم الباقة '),
                CardCount: this.$helper.RequiredInput('عدد الكروت '),
                CardAmount: this.$helper.RequiredInput('سعر الكرت '),
            },

        };
    },

    methods: {


        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.Id = Number(0);
                    this.ruleForm.CardCount = Number(this.ruleForm.CardCount);
                    this.ruleForm.CardAmount = Number(this.ruleForm.CardAmount);
                    this.$blockUI.Start();
                    this.$http.AddVoucherCards(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    },
};
