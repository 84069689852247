/*import swal from "sweetalert";*/


import ReportsDoughnutChart from "../Charts/ReportsDoughnutChart.vue";
import ThinBarChart from "../Charts/ThinBarChart.vue";

import HelperMixin from '../../Shared/HelperMixin.vue'
export default {
    name: 'home',
    mixins: [HelperMixin],
    components: {
        ReportsDoughnutChart,
        ThinBarChart,
    },

    async created() {
        window.scrollTo(0, 0);
        await this.CheckLoginStatus();
        this.GetDashboardInfo();
    },
    data() {
        return {

            chartTest: {


                type: "bar",
                data: {
                    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                    datasets: [{
                        label: "Watts",
                        tension: 0.4,
                        borderWidth: 0,
                        borderRadius: 4,
                        borderSkipped: false,
                        backgroundColor: "#3A416F",
                        data: [150, 230, 380, 220, 420, 200, 70],
                        maxBarThickness: 6
                    },],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        }
                    },
                    interaction: {
                        intersect: false,
                        mode: 'index',
                    },
                    scales: {
                        y: {
                            grid: {
                                drawBorder: false,
                                display: false,
                                drawOnChartArea: false,
                                drawTicks: false,
                            },
                            ticks: {
                                display: false
                            },
                        },
                        x: {
                            grid: {
                                drawBorder: false,
                                display: false,
                                drawOnChartArea: false,
                                drawTicks: false
                            },
                            ticks: {
                                beginAtZero: true,
                                font: {
                                    size: 12,
                                    family: "Open Sans",
                                    style: 'normal',
                                },
                                color: "#9ca2b7"
                            },
                        },
                        z: {
                            grid: {
                                drawBorder: false,
                                display: false,
                                drawOnChartArea: true,
                                drawTicks: false,
                                borderDash: [5, 5]
                            },
                            ticks: {
                                display: true,
                                padding: 10,
                                color: '#9ca2b7'
                            }
                        },
                        W: {
                            grid: {
                                drawBorder: false,
                                display: true,
                                drawOnChartArea: true,
                                drawTicks: false,
                                borderDash: [5, 5]
                            },
                            ticks: {
                                display: true,
                                padding: 10,
                                color: '#9ca2b7'
                            }
                        },
                    },
                },



            },



            pageNo: 1,
            pageSize: 5,
            pages: 0,
            state: 0,

            loginDetails: null,
            Info: [],
            Info2: [],
        ElSteps:0,
            ruleForm: {
                RegistryNumber: "",
                NationalId: "",
                Phone: "",
                Recaptcha: "",
            },
            Percentage: 0,
            loading: false,
            Voters: {},
            success: {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true,
                center: true,
            },
            error: {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true,
                center: true,
            },
            warning: {
                confirmButtonText: "OK",
                type: "warning",
                dangerouslyUseHTMLString: true,
                center: true,
            },    
        };
    },
    methods: {

        GetInfo() {
            this.$blockUI.Start();
            this.$http.GetDashboardInfo()
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },


     

        GetDashboardInfo() {

            this.$blockUI.Start();
            this.$http.GetDashboardInfo()
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });

        },


        //GetDashboardInfoSalse() {

        //    this.$blockUI.Start();
        //    this.$http.Sale(18)
        //        .then(response => {
        //            this.$blockUI.Stop();

        //            const url = window.URL.createObjectURL(new Blob([response.data]));
        //            const link = document.createElement('a');
        //            link.href = url;
        //            link.setAttribute('download', 'VoucherCards.csv');
        //            document.body.appendChild(link);
        //            link.click();
        //            document.body.removeChild(link);
        //        })
        //        .catch(() => {
        //            this.$blockUI.Stop();
        //        });

        //},


    }    
}
