import Swal from 'sweetalert2';
import moment from 'moment';
import flatPickr from "vue-flatpickr-component";
import HelperMixin from '../../Shared/HelperMixin.vue'
import axios from 'axios';
export default {
    name: 'Courses',
    mixins: [HelperMixin],
    async created() {

        await this.CheckLoginStatus();
        if (this.loginDetails.userType != 40)
            this.logout();

        await this.GetInstructorName();
        await this.GetCities();
        await this.GetAcademicLevels();
        await this.GetSupjectName();
        this.GetInfo();

    },
    components: {
        flatPickr,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,

            file: null,
            chunks: [],
            uploaded: 0,
            chunkNumber: 1,
            ShowProgress: false,



            AcademicLevelId: '',
            AcademicSpecializationId: '',
            SubjectId: '',
            CourseId: '',


            Info: [],
            Shapters: [],
            Lectures: [],
            Exams: [],
            StudentCourse: [],

            selectedItem: '',
            selectedShapter: '',
            selectedLecture: '',

            photo: '',

            StudentDiloag: false,
            ValueDiloag: false,
            AddShapterDiloag: false,
            EditShapterDiloag: false,
            AddLectureDiloag: false,
            EditLectureDiloag: false,
            AddLectureAttahcmentDiloag: false,
            AddExamQuestionDilog: false,
            EditExamDilog: false,
            ShowVideo: false,


            //config: {
            //    allowInput: true,
            //},


            ruleForm: {
                Id: 0,

                AcademicLevelId: '',
                AcademicSpecializationId: '',
                SubjectId: '',
                InstructorId: '',
                IntroUrl: '',
                Name: '',
                EnglishName: '',
                Price: 0,
                Descriptions: '',
                TaregerLevel: '',
                IsFree: false,

            },
            rules: {
                AcademicLevelId: this.$helper.Required(),
                AcademicSpecializationId: this.$helper.Required(),
                SubjectId: this.$helper.Required(),
                InstructorId: this.$helper.Required(),
                Name: this.$helper.Required('الاسم '),
                EnglishName: this.$helper.Required(' الاسم '),
                Price: this.$helper.NumberOnlyRequired(),
                TaregerLevel: this.$helper.Required(),
                IntroUrl: this.$helper.Required(),
            },

            ImageruleForm: {
                Id: 0,
                ImageName: '',
                fileBase64: '',

            },

            ShapterruleForm: {
                Id: 0,
                CourseId: '',
                Name: '',
                EnglishName: '',
                Descriptions: '',
                Number: '',
            },
            Shapterrules: {
                Name: this.$helper.Required('الاسم '),
                EnglishName: this.$helper.Required(' الاسم '),
                Number: this.$helper.NumberOnlyRequired(),
            },


            LecturesruleForm: {
                Id: 0,
                ShapterId: '',
                Number: '',
                Name: '',
                EnglishName: '',
                Descriptions: '',
                LectureAttashmentsList: [],
            },
            Lecturesrules: {
                Name: this.$helper.Required('الاسم '),
                EnglishName: this.$helper.Required(' الاسم '),
                Number: this.$helper.NumberOnlyRequired(),
            },

            Auth: {
                otp: '',
                playbackInfo: '',
            },


            LectureAttashmentsruleForm: {
                Id: 0,
                LectureId: '',
                Number: '',
                Name: '',
                Type: '',
                Descriptions: '',
                fileBase64: '',
            },
            LectureAttashmentsrules: {
                Name: this.$helper.Required('الاسم '),
                Number: this.$helper.NumberOnlyRequired(),
                Type: this.$helper.Required(),
            },


            ExamForm: {
                Id: 0,
                ShapterId: '',
                Name: '',
                EnglishName: '',
                Descriptions: '',
                Number: '',
                ExamQuestions: [],
            },
            Examrules: {
                Name: this.$helper.Required('الاسم '),
                Number: this.$helper.NumberOnlyRequired(),
            },

            QuestionsForm: {
                Id: 0,
                ExamId: 0,
                Question: '',
                Marck: '',
                Type: '',
                Answer: '',
                A1: '',
                A2: '',
                A3: '',
                A4: '',
            },
            Questionsrules: {
                Question: this.$helper.Required('الاسم '),
                Marck: this.$helper.NumberOnlyRequired(),
                Type: this.$helper.Required(),
                Answer: this.$helper.Required(),
                A1: this.$helper.Required(),
                A2: this.$helper.Required(),
                A3: this.$helper.Required(),
                A4: this.$helper.Required(),
            },




        };
    },
    watch: {
        chunks(n) {
            if (n.length > 0) {
                this.upload();
            }
        }
    },

    computed: {
        progress() {
            if (this.file) {
                //debugger
                return Math.floor((this.uploaded / this.file.size) * 100);
            }

        },
        formData() {
            //debugger
            let formData = new FormData;

            formData.set('is_last', this.chunks.length === 1);
            formData.set('file', this.chunks[0], `${this.file.name}`);
            formData.set('Number', this.LectureAttashmentsruleForm.Number);
            formData.set('LectureId', this.LectureAttashmentsruleForm.LectureId);
            formData.set('Type', this.LectureAttashmentsruleForm.Type);
            formData.set('Name', this.LectureAttashmentsruleForm.Name);

            return formData;
        },
        config() {
            return {
                method: 'POST',
                data: this.formData,
                url: '/Api/Admin/Lectures/AddAttachmentTest',
                headers: {
                    'Content-Type': 'application/octet-stream'
                },
                onUploadProgress: event => {
                    this.uploaded += event.loaded;
                }
            };
        }
    },

    methods: {


        async FilterGetAcademicSpecializationName() {
            this.AcademicSpecializationId = '';
            this.AcademicSpecialization = [];
            this.SubjectId = '';
            this.Subjects = [];
            this.CourseId = '';
            this.Courses = [];
            this.GetInfo();
            await this.GetAcademicSpecialization(this.AcademicLevelId);
        },

        async FilterGetSupjectName() {
            this.SubjectId = '';
            this.Subjects = [];
            this.CourseId = '';
            this.Courses = [];
            this.GetInfo();
            await this.GetSupjects();
        },

        async FilterCourseNameInfo() {
            this.CourseId = '';
            this.Courses = [];
            this.GetInfo();
            await this.GetCourseName(this.AcademicSpecializationId, this.SubjectId);
        },



        OpenAddItemDilog() {
            this.state = 1;
        },

        async GetMunicipalitiesName() {
            this.ruleForm.MunicipalitId = '',
                await this.GetMunicipalities(this.ruleForm.CityId)
        },

        async GetLocationsName() {
            this.ruleForm.LocationId = '',
                await this.GetLocations(this.ruleForm.MunicipalityId)
        },

        async GetAcademicSpecializationName() {
            this.ruleForm.AcademicSpecializationId = '',
                await this.GetAcademicSpecialization(this.ruleForm.AcademicLevelId)
        },

        async GetSupjectName() {
            this.ruleForm.SubjectId = '',
                await this.GetSupjects()
        },

        async GetInstructorName() {
            this.ruleForm.InstructorId = '',
                await this.GetInstructors()
        },

        submitForm(formName, type) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (type == 1) {
                        this.Add(formName);
                    } else {
                        this.Edit(formName);
                    }

                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        Add(formName) {
            this.ruleForm.Id = Number(this.ruleForm.Id);
            this.ruleForm.InstructorId = Number(1);
            this.ruleForm.Price = Number(this.ruleForm.Price);
            this.$blockUI.Start();
            this.$http.InstractorAddCourses(this.ruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.GetInfo();
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.state = 0;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                });
        },

        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            this.$blockUI.Start();
            this.$http.InstractorGetCourses(this.pageNo, this.pageSize, this.AcademicLevelId, this.AcademicSpecializationId, this.SubjectId, this.CourseId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        OpenEdit(item) {
            this.state = 2;
            this.selectedItem = item;
            this.ruleForm.Id = item.id;
            this.ruleForm.AcademicLevelId = item.academicSpecialization.academicLevelId;
            this.GetAcademicSpecializationName();
            this.ruleForm.AcademicSpecializationId = item.academicSpecialization.academicSpecializationId;
            this.ruleForm.SubjectId = item.subject.subjectId;
            this.ruleForm.InstructorId = item.instructor.instructorId;
            this.ruleForm.IntroUrl = item.introUrl;

            this.ruleForm.Name = item.name;
            this.ruleForm.EnglishName = item.englishName;
            this.ruleForm.Price = item.price;

            this.ruleForm.Descriptions = item.descriptions;
            this.ruleForm.TaregerLevel = item.taregerLevel;
        },

        Edit(formName) {
            this.$blockUI.Start();
            this.ruleForm.Id = Number(this.ruleForm.Id);
            this.ruleForm.Price = Number(this.ruleForm.Price);
            this.ruleForm.AcademicLevelId = Number(this.ruleForm.AcademicLevelId);
            this.ruleForm.AcademicSpecializationId = Number(this.ruleForm.AcademicSpecializationId);
            this.$http.EditCourses(this.ruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.GetInfo();
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.state = 0;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                });
        },

        Delete(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteCourses(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            })
        },

        ChangeStatus(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية تغير حالة العرض ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.CoursesChangeStatus(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية تغير الحالة', err.response.data);
                        });
                    return;
                }
            })
        },

        Reject(id) {
            Swal.fire({
                title: 'هـل انت متأكد من رفض الدورة التدريبية ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.CoursesReject(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية تغير الحالة', err.response.data);
                        });
                    return;
                }
            })
        },

        Accept(id) {
            Swal.fire({
                title: 'هـل انت متأكد من قبول الدورة التدريبية ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.CoursesAccept(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية تغير الحالة', err.response.data);
                        });
                    return;
                }
            })
        },

        SelectCoverAttachment(file) {
            let str = file.raw.type;
            str = str.substring(0, 5);

            if (str != "image") {
                this.$helper.ShowMessage('error', 'خطأ بالعملية', 'الرجاء التأكد من نوع الملف');
            }

            var $this = this;
            var reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = function () {
                $this.ImageruleForm.ImageName = file.raw.name;
                $this.ImageruleForm.fileBase64 = reader.result;
            };

            this.ImageruleForm.Id = this.selectedItem.id;
            this.$http.ChangeCourseProfilePicture(this.ImageruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.GetInfo();
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بالعملية', err.response.data);
                });

        },



        OpenStudentDiloag(item) {
            this.StudentDiloag = true;
            this.selectedItem = item;
            this.GetStudentCourse(item.id);
        },

        GetStudentCourse(id) {

            this.$blockUI.Start();
            this.$http.GetCourseStudent(id)
                .then(response => {
                    this.$blockUI.Stop();
                    this.StudentCourse = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        OpenValueDiloag() {
            this.ValueDiloag = true;
        },

        OpenCourseProfile(item) {
            this.selectedItem = item;
            this.state = 3;
            this.GetShapter();
        },




        GetShapter() {
            this.$blockUI.Start();
            this.$http.GetCourseShapters(this.selectedItem.id)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Shapters = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        OpenAddShapterDilog() {
            this.AddShapterDiloag = true;
            this.ShapterruleForm.Id = 0;
            this.ShapterruleForm.CourseId = '';
            this.ShapterruleForm.Name = '';
            this.ShapterruleForm.EnglishName = '';
            this.ShapterruleForm.Descriptions = '';
            this.ShapterruleForm.Number = '';
        },

        submitShapterForm(formName, type) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (type == 1) {
                        this.AddShapter(formName);
                    } else {
                        this.EditShapter(formName);
                    }

                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        AddShapter(formName) {
            this.ShapterruleForm.Id = Number(this.ShapterruleForm.Id);
            this.ShapterruleForm.CourseId = Number(this.selectedItem.id);
            this.ShapterruleForm.Number = Number(this.ShapterruleForm.Number);
            this.$blockUI.Start();
            this.$http.AddShapters(this.ShapterruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.GetInfo();
                    this.GetShapter();
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.AddShapterDiloag = false;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بالعملية', err.response.data);
                });
        },

        OpenEditShapter(item) {
            this.selectedShapter = item;
            this.ShapterruleForm.Id = item.id;
            this.ShapterruleForm.CourseId = this.selectedItem.id;
            this.ShapterruleForm.Name = item.name;
            this.ShapterruleForm.EnglishName = item.englishName;
            this.ShapterruleForm.Number = item.number;
            this.ShapterruleForm.Descriptions = item.descriptions;
            this.EditShapterDiloag = true;
        },

        EditShapter(formName) {
            this.$blockUI.Start();
            this.ShapterruleForm.Id = Number(this.ShapterruleForm.Id);
            this.ShapterruleForm.Number = Number(this.ShapterruleForm.Number);
            this.$http.EditShapters(this.ShapterruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.GetInfo();
                    this.GetShapter();
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.EditShapterDiloag = false;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                });
        },

        DeleteShapter(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteShapters(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.GetShapter();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            })
        },



        OpenShapterProfile(item) {
            this.selectedShapter = item;
            this.state = 4;
            this.GetLectures();
        },

        GetLectures() {
            this.$blockUI.Start();
            this.$http.GetShapterLectures(this.selectedShapter.id)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Lectures = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        GetLecturesAttahcmnts() {
            this.$blockUI.Start();
            this.$http.GetLecturesAttachments(this.selectedLecture.id)
                .then(response => {
                    this.$blockUI.Stop();
                    this.LecturesruleForm.LectureAttashmentsList = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        OpenAddLecturesDilog() {
            this.state = 5;
            this.LecturesruleForm.Id = 0;
            this.LecturesruleForm.ShapterId = '';
            this.LecturesruleForm.Number = '';
            this.LecturesruleForm.Name = '';
            this.LecturesruleForm.EnglishName = '';
            this.LecturesruleForm.Descriptions = '';
            this.LecturesruleForm.LectureAttashmentsList = [];
        },

        SelectAttachment(file) {
            let str = file.raw.type;
            //pptx
            if (str == "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
                this.LectureAttashmentsruleForm.Type = 3;
            }

            if (str == "application/pdf") {
                this.LectureAttashmentsruleForm.Type = 2;
            }

            str = str.substring(0, 5);

            if (str == "image") {
                this.LectureAttashmentsruleForm.Type = 3;
            }

            if (str == "video") {
                this.LectureAttashmentsruleForm.Type = 1;
            }



            var $this = this;
            var reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = function () {
                $this.LectureAttashmentsruleForm.Name = file.raw.name;
                $this.LectureAttashmentsruleForm.fileBase64 = reader.result;
            };
        },

        submitLectureAttachmentForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.LectureAttashmentsruleForm.Number = Number(this.LectureAttashmentsruleForm.Number);
                    let obj = {
                        Type: this.LectureAttashmentsruleForm.Type,
                        Descriptions: this.LectureAttashmentsruleForm.Descriptions,
                        Name: this.LectureAttashmentsruleForm.Name,
                        Number: this.LectureAttashmentsruleForm.Number,
                        fileBase64: this.LectureAttashmentsruleForm.fileBase64,
                    }
                    this.LecturesruleForm.LectureAttashmentsList.push(obj);
                    this.LectureAttashmentsruleForm.Descriptions = '';
                    this.LectureAttashmentsruleForm.Type = '';
                    this.LectureAttashmentsruleForm.Name = '';
                    this.LectureAttashmentsruleForm.Number = '';
                    this.LectureAttashmentsruleForm.fileBase64 = '';
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        DeleteLectureAttachment(index) {
            this.LecturesruleForm.LectureAttashmentsList.splice(index, 1);
        },

        DeleteLectureAttachments(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteLecturesAttachment(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.GetShapter();
                            this.GetLectures();
                            this.GetLecturesAttahcmnts();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            })
        },

        ShowVideoInfo(id) {
            this.ShowVideo = true;
            this.$blockUI.Start();
            this.$http.OpenVideo(id)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Auth.otp = response.data.info.otp;
                    this.Auth.playbackInfo = response.data.info.playbackInfo;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        OpenAddLecturesAttachmentDilog() {
            this.AddLectureAttahcmentDiloag = true;
            this.LectureAttashmentsruleForm.LectureId = this.selectedLecture.id;
            this.LectureAttashmentsruleForm.Number = '';
            this.LectureAttashmentsruleForm.Name = '';
            this.LectureAttashmentsruleForm.Type = '';
            this.LectureAttashmentsruleForm.Descriptions = '';
            this.LectureAttashmentsruleForm.fileBase64 = '';
        },

        submitAddLectureAttachmentForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.LectureAttashmentsruleForm.Id = Number(this.LectureAttashmentsruleForm.Id);
                    this.LectureAttashmentsruleForm.LectureId = Number(this.LectureAttashmentsruleForm.LectureId);
                    this.LectureAttashmentsruleForm.Number = Number(this.LectureAttashmentsruleForm.Number);
                    this.createChunks();
                    //this.resetForm(formName);
                    //this.GetInfo();
                    //this.GetShapter();
                    //this.GetLectures();
                    //this.GetLecturesAttahcmnts();

                    //this.$blockUI.Start();
                    //this.$http.AddLecturesAttachments(this.LectureAttashmentsruleForm)
                    //    .then(response => {
                    //        this.$blockUI.Stop();
                    //        this.resetForm(formName);
                    //        this.GetInfo();
                    //        this.GetShapter();
                    //        this.GetLectures();
                    //        this.GetLecturesAttahcmnts();
                    //        this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    //        this.AddLectureAttahcmentDiloag = false;
                    //    })
                    //    .catch((err) => {
                    //        this.$blockUI.Stop();
                    //        this.$helper.ShowMessage('error', 'خطأ بالعملية', err.response.data);
                    //    });

                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        submitLectureForm(formName, type) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (type == 1) {
                        this.AddLecture(formName);
                    } else {
                        this.EditLecture(formName);
                    }

                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        AddLecture(formName) {
            this.LecturesruleForm.Id = Number(this.LecturesruleForm.Id);
            this.LecturesruleForm.ShapterId = Number(this.selectedShapter.id);
            this.LecturesruleForm.Number = Number(this.LecturesruleForm.Number);
            this.$blockUI.Start();
            this.$http.AddLectures(this.LecturesruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.GetInfo();
                    this.GetShapter();
                    this.GetLectures();
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.state = 4;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بالعملية', err.response.data);
                });
        },

        OpenLectureProfile(item) {
            this.selectedLecture = item;
            this.LecturesruleForm.Id = item.id;
            this.LecturesruleForm.Name = item.name;
            this.LecturesruleForm.EnglishName = item.englishName;
            this.LecturesruleForm.Number = item.number;
            this.LecturesruleForm.Descriptions = item.descriptions;
            this.LecturesruleForm.LectureAttashmentsList = item.lectureAttachments;
            this.state = 6;
        },

        OpenEditLecture(item) {
            this.selectedLecture = item;
            this.LecturesruleForm.Id = item.id;
            this.LecturesruleForm.Name = item.name;
            this.LecturesruleForm.EnglishName = item.englishName;
            this.LecturesruleForm.Number = item.number;
            this.LecturesruleForm.Descriptions = item.descriptions;
            this.EditLectureDiloag = true;
        },

        EditLecture(formName) {
            this.$blockUI.Start();
            this.LecturesruleForm.Id = Number(this.LecturesruleForm.Id);
            this.LecturesruleForm.Number = Number(this.LecturesruleForm.Number);
            this.LecturesruleForm.ShapterId = Number(this.selectedShapter.id);
            this.$http.EditLectures(this.LecturesruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.GetInfo();
                    this.GetShapter();
                    this.GetLectures();
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.EditLectureDiloag = false;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                });
        },

        DeleteLecture(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteLectures(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.GetShapter();
                            this.GetLectures();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            })
        },



        OpenExamProfile(item) {
            this.selectedShapter = item;
            this.state = 7;
            this.GetExams();
        },

        OpenAddExamDilog() {
            this.state = 8;
            this.ExamForm.Id = 0;
            this.ExamForm.ShapterId = '';
            this.ExamForm.Name = '';
            this.ExamForm.EnglishName = '';
            this.ExamForm.Descriptions = '';
            this.ExamForm.Number = '';
            this.ExamForm.ExamQuestions = [];
        },

        submitQuestionsForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.QuestionsForm.Marck = Number(this.QuestionsForm.Marck);
                    let obj = {
                        //ExamId: 0,
                        Question: this.QuestionsForm.Question,
                        Marck: this.QuestionsForm.Marck,
                        Type: this.QuestionsForm.Type,
                        Answer: this.QuestionsForm.Answer,
                        A1: this.QuestionsForm.A1,
                        A2: this.QuestionsForm.A2,
                        A3: this.QuestionsForm.A3,
                        A4: this.QuestionsForm.A4,
                    }
                    this.ExamForm.ExamQuestions.push(obj);
                    //this.QuestionsForm.ExamId = '';
                    this.QuestionsForm.Question = '';
                    this.QuestionsForm.Marck = '';
                    this.QuestionsForm.Type = '';
                    this.QuestionsForm.Answer = '';
                    this.QuestionsForm.A1 = '';
                    this.QuestionsForm.A2 = '';
                    this.QuestionsForm.A3 = '';
                    this.QuestionsForm.A4 = '';
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        DeleteQuestions(index) {
            this.ExamForm.ExamQuestions.splice(index, 1);
        },

        DeleteExamQuestions(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteExamQuestion(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.GetShapter();
                            this.GetExams();
                            this.GetExamQuestion();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            })
        },

        submitExamForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ExamForm.Id = Number(this.ExamForm.Id);
                    this.ExamForm.ShapterId = Number(this.selectedShapter.id);
                    this.ExamForm.Number = Number(this.ExamForm.Number);
                    this.$blockUI.Start();
                    this.$http.AddCourseExam(this.ExamForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.GetInfo();
                            this.GetShapter();
                            this.GetExams();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.state = 7;
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بالعملية', err.response.data);
                        });

                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        GetExams() {
            this.$blockUI.Start();
            this.$http.GetCourseExams(this.selectedShapter.id)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Exams = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetExamQuestion() {
            this.$blockUI.Start();
            this.$http.GetCourseExamsQuestion(this.selectedExam.id)
                .then(response => {
                    this.$blockUI.Stop();
                    this.ExamForm.ExamQuestions = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        OpenExamProfileInfo(item) {
            this.selectedExam = item;
            this.ExamForm.Id = item.id;
            this.ExamForm.Name = item.name;
            this.ExamForm.EnglishName = item.englishName;
            this.ExamForm.Number = item.number;
            this.ExamForm.Descriptions = item.descriptions;
            this.ExamForm.ExamQuestions = item.examQuestions;
            this.state = 9;
        },

        OpenAddExamQuestionDilog() {
            this.AddExamQuestionDilog = true;
            this.QuestionsForm.ExamId = this.selectedExam.id;
            this.QuestionsForm.Question = '';
            this.QuestionsForm.Marck = '';
            this.QuestionsForm.Type = '';
            this.QuestionsForm.Answer = '';
            this.QuestionsForm.A1 = '';
            this.QuestionsForm.A2 = '';
            this.QuestionsForm.A3 = '';
            this.QuestionsForm.A4 = '';
        },

        submitAddQuestionsForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.QuestionsForm.Id = Number(this.QuestionsForm.Id);
                    this.QuestionsForm.ExamId = Number(this.ExamForm.Id);
                    this.QuestionsForm.Marck = Number(this.QuestionsForm.Marck);
                    this.$blockUI.Start();
                    this.$http.AddCourseExamQuestion(this.QuestionsForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.GetInfo();
                            this.GetShapter();
                            this.GetExams();
                            this.GetExamQuestion();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.AddExamQuestionDilog = false;
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بالعملية', err.response.data);
                        });

                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        OpenEditExam(item) {
            this.selectedExam = item;
            this.ExamForm.Id = item.id;
            this.ExamForm.Name = item.name;
            this.ExamForm.EnglishName = item.englishName;
            this.ExamForm.Number = item.number;
            this.ExamForm.Descriptions = item.descriptions;
            this.ExamForm.ExamQuestions = item.examQuestions;
            this.EditExamDilog = true;
        },

        submitEditExamForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ExamForm.Id = Number(this.ExamForm.Id);
                    this.ExamForm.ShapterId = Number(this.selectedShapter.id);
                    this.ExamForm.Number = Number(this.ExamForm.Number);
                    this.$blockUI.Start();
                    this.$http.EditCourseExam(this.ExamForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.GetInfo();
                            this.GetShapter();
                            this.GetExams();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.EditExamDilog = false;
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بالعملية', err.response.data);
                        });

                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        DeleteExam(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteExam(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.GetShapter();
                            this.GetExams();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            })
        },

        ChangeStatusExam(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية تغير الحالة ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.ChangeStatusCourseExam(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.GetShapter();
                            this.GetExams();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية تغير الحالة', err.response.data);
                        });
                    return;
                }
            })
        },












        //SelectAttachment1(file) {

        //    var $this = this;
        //    var reader = new FileReader();
        //    reader.readAsDataURL(file.raw);
        //    reader.onload = function () {
        //        $this.LectureAttashmentsruleForm.Name = file.raw.name;
        //        $this.LectureAttashmentsruleForm.fileBase64 = reader.result;
        //    };
        //},

        select(event) {
            //debugger
            this.file = event.target.files.item(0);
            let str = event.target.files.item(0).type;
            if (str == "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
                this.LectureAttashmentsruleForm.Type = 3;
            }

            if (str == "application/pdf") {
                this.LectureAttashmentsruleForm.Type = 2;
            }

            str = str.substring(0, 5);

            if (str == "image") {
                this.LectureAttashmentsruleForm.Type = 3;
            }

            if (str == "video") {
                this.LectureAttashmentsruleForm.Type = 1;
            }

            this.LectureAttashmentsruleForm.Name = event.target.files.item(0).name;
            this.ShowProgress = true;
            //this.createChunks();
        },
        upload() {

            axios(this.config).then(response => {
                this.chunks.shift();
                //this.Lectures = response;
                if (response.data.last == true) {
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data.message);
                    this.file = null;
                    this.chunks = [];
                    this.uploaded = 0;
                    this.LectureAttashmentsruleForm.Type = '';
                    this.GetInfo();
                    this.GetShapter();
                    this.GetLectures();
                    this.GetLecturesAttahcmnts();
                }

            }).catch(error => {
                this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', error.response.data);
                this.file = null;
                this.chunks = [];
                this.uploaded = 0;
            });
        },
        createChunks() {
            let size = 1048576 * 3, chunks = Math.ceil(this.file.size / size);

            for (let i = 0; i < chunks; i++) {
                this.chunks.push(this.file.slice(
                    i * size, Math.min(i * size + size, this.file.size), this.file.type
                ));
            }
        }






        //SelectAttachment1(input) {
        //    const file = input.files[0];
        //    const filename = input.files[0].name;
        //    var numberofChunks = Math.ceil(file.size / chunkSize);
        //    document.getElementById("video-information").innerHTML = "There will be " + numberofChunks + " chunks uploaded."
        //    var start = 0;
        //    var chunkEnd = start + chunkSize;
        //    //upload the first chunk to get the videoId
        //    this.createChunk(videoId, start);
        //},

        //createChunk(videoId, start, end) {
        //    this.chunkCounter++;
        //    console.log("created chunk: ", chunkCounter);
        //    this.chunkEnd = Math.min(start + chunkSize, file.size);
        //    const chunk = file.slice(start, chunkEnd);
        //    console.log("i created a chunk of video" + start + "-" + chunkEnd + "minus 1	");
        //    const chunkForm = new FormData();
        //    if (videoId.length > 0) {
        //        //we have a videoId
        //        chunkForm.append('videoId', videoId);
        //        console.log("added videoId");

        //    }
        //    chunkForm.append('file', chunk, filename);
        //    console.log("added file");


        //    //created the chunk, now upload iit
        //    this.uploadChunk(chunkForm, start, chunkEnd);
        //},

        //uploadChunk(chunkForm, start, chunkEnd) {
        //    var oReq = new XMLHttpRequest();
        //    oReq.upload.addEventListener("progress", updateProgress);
        //    oReq.open("POST", url, true);
        //    var blobEnd = chunkEnd - 1;
        //    var contentRange = "bytes " + start + "-" + blobEnd + "/" + file.size;
        //    oReq.setRequestHeader("Content-Range", contentRange);
        //    console.log("Content-Range", contentRange);

        //},


        //updateProgress(oEvent) {
        //    if (oEvent.lengthComputable) {
        //        var percentComplete = Math.round(oEvent.loaded / oEvent.total * 100);

        //        var totalPercentComplete = Math.round((chunkCounter - 1) / numberofChunks * 100 + percentComplete / numberofChunks);
        //        document.getElementById("chunk-information").innerHTML = "Chunk # " + chunkCounter + " is " + percentComplete + "% uploaded. Total uploaded: " + totalPercentComplete + "%";
        //        //	console.log (percentComplete);
        //        // ...
        //    } else {
        //        console.log("not computable");
        //        // Unable to compute progress information since the total size is unknown
        //    }
        //},

        //oReq : function (oEvent) {
        //    console.log("uploaded chunk");
        //    console.log("oReq.response", oReq.response);
        //    var resp = JSON.parse(oReq.response)
        //    videoId = resp.videoId;
        //    //playerUrl = resp.assets.player;
        //    console.log("videoId", videoId);

        //    //now we have the video ID - loop through and add the remaining chunks
        //    //we start one chunk in, as we have uploaded the first one.
        //    //next chunk starts at + chunkSize from start
        //    start += chunkSize;

        //    if (start < file.size) {
        //                //create the new chunk
        //                createChunk(videoId, start);
        //            }
        //            else {
        //                //the video is fully uploaded. there will now be a url in the response
        //                playerUrl = resp.assets.player;
        //                console.log("all uploaded! Watch here: ", playerUrl);
        //                document.getElementById("video-information").innerHTML = "all uploaded! Watch the video <a href=\'" + playerUrl + "\' target=\'_blank\'>here</a>";
        //            }

        //    xhr.onreadystatechange = function () {
        //        if (this.readyState === XMLHttpRequest.DONE) {
        //            if (this.status === 200) {
        //                //Does not refer to customerArray
        //                this.customerArray = JSON.parse(this.responseText);
        //            } else {
        //                console.log(this.status, this.statusText);
        //            }
        //        }
        //    };
        //    xhr.send();
        //}



    }
}
