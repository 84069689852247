import { render, staticRenderFns } from "./Municipalities.html?vue&type=template&id=0806edaf&external"
import script from "./Municipalities.js?vue&type=script&lang=js&external"
export * from "./Municipalities.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports