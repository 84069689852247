import shapters from './Shapters/Shapters.vue';
import Swal from 'sweetalert2'
import moment from 'moment';
import flatPickr from "vue-flatpickr-component";
import HelperMixin from '../../Shared/HelperMixin.vue'
export default {

    name: 'Trainingcenter',
    mixins: [HelperMixin],
    async created() {
        await this.GetCities();
        await this.GetAcademicLevels();
        this.GetInfo();
        this.GetInfo1();
        this.GetInfo2();
        this.GetSubjects();
        //this.GetallMunicipalities() 
        //this.GetallLocations() 
    },
    components: {
        flatPickr,
        'Trainingcenter-shapters': shapters,

    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
        return {
            loginDetails:null,
            showMenu: false,
            activeClass: "js-active position-relative",
            activeStep: 0,
            formSteps: 3,
            config: {
                allowInput: true,
            },
            Diloag: false,
            pageNo: 1,
            pageSize: 10,
            pageSize1: 5,
            pages: 0,
            state: 0,
            Info: [],
            Info1: [],
            Info2: [],
            EditUsersObj: [],
            imageUrl: '',

            Cities: [],
            Instructors: [],
            MunicipalityId: [],
            Locations: [],
            LocationId: [],
            Resone: {
                RejectResone:'',

            },
            ruleForm: {  
                CenterName:'',
                LoginName:'',
                Phone: '',
                ExtraPhone: '',
                Email: '',
                Facebook: '',
                Password: '',
                ConfirmPassword: '',
                CityId: '',
                MunicipalityId: '',
                LocationId: '',
                fileBase64: '',
                fileName: '',
            },  
            CourseForm: {
                
                AcademicSpecializationId: '',
                SubjectId: '',
                InstructorId: '',
                Name: '',
                EnglishName: '',
                Descriptions: '',
                TeacherName: '',
                TeacherRate: null,
                Rate: null,
                fileBase64: '',
                fileName: '',
                IsFree: 1,
                Price: null,
                CoverDescriptions: '',
                TaregerLevel: null,
                radio: null,

            },
            CourseForm1: {

                //AcademicSpecializationId: '',
                SubjectId: '',
                InstructorId: '',
                Name: '',
                EnglishName: '',
                Descriptions: '',
                TeacherName: '',
                TeacherRate: null,
                Rate: null,
                IsFree: 1,
                Price: null,
                TaregerLevel: null,
            },
            form: {
                CenterName: '',
                LoginName: '',
                Phone: '',
                ExtraPhone: '',
                Email: '',
                Facebook: '',
                //Password: '',

                //CityId: '',
                /*MunicipalityId: '',*/
                /*LocationId: '',*/
            },

            
            rules: {
                //AcademicSpecializationId: this.$helper.Required(),
                SubjectId: this.$helper.Required(),
                RejectResone: this.$helper.DynamicArabicEnterRequired(' سبب الرفض'),
                //AcademicLevelId: this.$helper.Required(),
                Name: this.$helper.DynamicArabicEnterRequired(' اسم المادة'),
                teacherName: this.$helper.Required(),
                taregerLevel: this.$helper.Required(),
              

            },

        };
    },
    watch: {
        'ruleForm.CityId'(){
            this.GetallMunicipalitiess();
            this.ruleForm.MunicipalityId = '';
        }
    },

    methods: {
        //href(url) {
            
        //    this.$router.push(url);
        //    /* this.selectedItem = item;*/
        //    //this.ruleForm.Id = this.$parent.id;
        //    //this.ruleForm.Id = item.id;
            
        //},

        FileChanged(e) {
            var files = e.target.files;
            if (files.length <= 0) {
                return;
            }
            //if (files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            //    this.ShowMessage("خطأ بعملية الاضافة", "الرجاء التأكد من الملف ", "warning");
            //    this.photo = null;
            //    window.location.href = '/Attachments/Supplier Item .xlsx'
            //    return;
            //}
            var $this = this;
            var reader = new FileReader();
            reader.onload = function () {
                //var obj =
                //{
                //    fileName: files[0].name,
                //    fileBase64: reader.result,
                //};
                this.ruleForm.fileName = files[0].name;
                this.ruleForm.fileBase64 = reader.result;
                /*$this.UploadImage(obj);*/
            };
            reader.onerror = function () {
                $this.photo = null;
            };
            reader.readAsDataURL(files[0]);
        },
        Image(e) {
            var files = e.target.files;
            if (files.length <= 0) {
                return;
            }
           
            var $this = this;
            var reader = new FileReader();
            reader.onload = function () {
               
                this.CourseForm.fileName = files[0].name;
                this.CourseForm.fileBase64 = reader.result;
            };
            reader.onerror = function () {
                $this.photo = null;
            };
            reader.readAsDataURL(files[0]);
        },


        CheckLoginStatus() {
            try {
                this.loginDetails = JSON.parse(localStorage.getItem('currentUser-client'));
                if (this.loginDetails == null) {
                    window.location.href = '/login';
                }
            } catch (error) {
                window.location.href = '/login';
            }
        },

        //UploadImage(obj) {
        //    this.$blockUI.Start();
        //    this.$http.UploadImages(obj)
        //        .then(response => {
        //            this.$blockUI.Stop();
        //            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
        //            //this.GetInfo();
        //        })
        //        .catch((err) => {
        //            this.$blockUI.Stop(err);
        //            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
        //            //this.ShowMessage("خطأ بعملية الاضافة", err.response.data, "warning");
        //        });
        //},
        shapters(User) {
            this.state = 8;
            //this.GetInfo1();
            //this.selectedItem = item;
            this.EditUsersObj = User;
            this.CourseForm.Id = this.$parent.EditUsersObj.id;

            //this.CourseForm.Id = item.id;
            //this.Obj = Shapters;
        },
      
        OpenDiloag(item) {
            this.Diloag = true;
            this.Resone.Id = item.id;
        },
        OpenAddItem() {
            this.state = 1;
            this.Clear();
        },
        OpenStudentProfile(item) {
            
            this.selectedItem = item;
            this.CourseForm.InstructorId = item.id;
            this.GetInfo1();
            //this.CourseForm.Name = item.name;
        //    this.GetInfo1();
            this.state = 6;
        },
        OpenAdd(item) {
            this.state = 4;
            this.Clear3();
            //this.selectedItem = item;

            this.CourseForm.InstructorId = item.id;
            


 
        },
        submitForm(formName) {
            
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Addcenter(formName);
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        submit(formName) {

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.AddCourses(formName);
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        Addcenter(formName,) { 
            this.$blockUI.Start();
            this.$http.Addcenters(this.ruleForm)
         
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.state = 0;
                    this.GetInfo();
                    this.Clear();
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                });
        },
        AddCourses(formName,) {
            this.CourseForm.InstructorId =Number (this.CourseForm.InstructorId);
            this.CourseForm.IsFree = Boolean(this.CourseForm.IsFree)
            this.CourseForm.TeacherRate = Number(this.CourseForm.TeacherRate)
            this.CourseForm.Rate = Number(this.CourseForm.Rate)
            this.CourseForm.Price = Number(this.CourseForm.Price)
            this.CourseForm.TaregerLevel = Number(this.CourseForm.TaregerLevel)
            this.$blockUI.Start();
            this.$http.AddCourse(this.CourseForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.state = 6;
                    this.GetInfo1();
                    this.Clear3();
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                });
        },

        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            this.$blockUI.Start();
            this.$http.Getcenters(this.pageNo, this.pageSize)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },
        GetInfo1(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            this.$blockUI.Start();
            this.$http.Getcourse(this.pageNo, this.pageSize1 ,this.CourseForm.InstructorId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info1 = response.data.info1;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },
        GetInfo2(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            this.$blockUI.Start();
            this.$http.Getcourses(this.pageNo, this.pageSize1, this.CourseForm.InstructorId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info2 = response.data.info2;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },
        Search() {
            this.InstructorId = '';
            this.GetInfo1();
        },
        GetCities() {
            
            this.CityId = [];
            this.CityId = '';
            this.$blockUI.Start();
            this.$http.GetCities1(this.ruleForm.MunicipalityId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Cities = response.data.info;
                })
                .catch (() => {
                    this.$blockUI.Stop();
                    
                })
        },
       
        GetallMunicipalities(id = '') {
            this.GetLocationsName();
            this.ruleForm.LocationId = '';
            this.Municipalities = [];
            this.MunicipalityId = '';
            this.$blockUI.Start();
            this.$http.GetallMunicipalities(this.ruleForm.CityId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Municipalities = response.data.info;
                    this.ruleForm.MunicipalityId = id;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                })
        },
        GetallMunicipalitiess() {
         
            this.GetLocationsName();
            this.ruleForm.LocationId = '';
            this.Municipalities = [];
            this.MunicipalityId = '';
            this.$blockUI.Start();
            this.$http.GetallMunicipalitiess(this.ruleForm.CityId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Municipalities = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                })
        },
        //GetallLocations() {
        //    this.LocationId = [];
        //    this.LocationId = '';
        //    this.$blockUI.Start();
        //    this.$http.GetallLocation(this.ruleForm.MunicipalityId)
        //        .then(response => {
        //            this.$blockUI.Stop();
        //            this.Locations = response.data.info;
        //        })
        //        .catch(() => {
        //            this.$blockUI.Stop();
        //        })
        //},

        async GetLocationsName() {
            this.ruleForm.LocationId = '';
            await this.GetLocations(this.ruleForm.MunicipalityId)
        },
        async GetAcademicSpecializationName() {
            this.ruleForm.AcademicSpecializationId = '',
                await this.GetAcademicSpecialization(this.CourseForm.AcademicLevelId)
        },
        GetSubjects() {

            this.CityId = [];
            this.CityId = '';
            this.$blockUI.Start();
            this.$http.GetSubject(this.CourseForm.SubjectId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Subjects = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();

                })
        },
     
        Deleteitem(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.Deletecenters(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                showCancelButton: true,
                                denyButtonText: `خروج`,
                            }).then(() => {
                                this.$blockUI.Stop();
                                this.GetInfo();
                            });


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية الحذف',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;

                }
            })
        },
        Delete(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.Deletecourse(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                showCancelButton: true,
                                denyButtonText: `خروج`,
                            }).then(() => {
                                this.$blockUI.Stop();
                                this.GetInfo1();
                                this.state = 6;
                            });


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية الحذف',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;

                }
            })
        },
        Accept(Id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية القبول ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.Accepted(Id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                showCancelButton: true,
                                //confirmButtonText: `حـفظ`,
                                denyButtonText: `خروج`,
                            }).then(() => {
                                this.GetInfo1();
                                this.state = 6;
                            });
                            this.$blockUI.Stop();

                            if (this.Courses.lenght === 1) {
                                this.pageNo--;
                                if (this.pageNo <= 0) {
                                    this.pageNo = 1;
                                }
                            }

                            

                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية القبول',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;
                }
            })
        },
        Reject(formName) {
            
                this.$blockUI.Start();
                this.$http.Rejected(this.Resone)
                    .then(response => {
                        this.resetForm(formName);
                        this.$blockUI.Stop();
                        this.$helper.ShowMessage('succsess', 'تمت عملية الرفض ', response.data);
                        this.Resone.RejectResone = '';
                        this.Diloag = false;
                        this.state = 6;
                        this.GetInfo1();
                        

                    })

                    .catch((err) => {

                        this.$blockUI.Stop();
                        this.$helper.ShowMessage('error', 'خطأ بعملية الرفض', err.response.data);
                    });

            },
        SubmitReject(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Reject(formName);

                } else {
                    return false;
                }
            });
        },
        ChangeStatus(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية تغير الحالة ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.ChangeStatusLocations(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                showCancelButton: true,
                                denyButtonText: `خروج`,
                            }).then(() => {
                                this.$blockUI.Stop();
                                this.GetInfo1();
                                this.state = 6;
                            });


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية تغير الحالة',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;

                }
            })
        },

        EditItem(item) {
            this.form.Id = item.id;
            this.selectedItem = item;
            this.ruleForm.CityId = item.cityId;
            this.form.CenterName = item.centerName;
            /*this.ruleForm.MunicipalityId = item.municipalityId;*/
            this.form.LoginName = item.userInfo.loginName;
            this.form.Facebook = item.facebook;
            this.form.Phone = item.userInfo.phone;
            this.form.ExtraPhone = item.userInfo.extraPhone;
            this.form.Email = item.userInfo.email;
            //this.ruleForm.Password = item.userInfo.password;
            this.ruleForm.LocationId = item.userInfo.locationId;
            this.GetallMunicipalities(item.municipalityId);
            this.state = 2;
        },
        Edit1(item) {
            this.CourseForm1.Id = item.id;
            this.CourseForm1.InstructorId = item.id;
            //this.selectedItem = item;
            //this.CourseForm1.AcademicSpecializationId = item.academicSpecialization.name;
            this.CourseForm1.SubjectId = item.subject.id;
            /*this.ruleForm.MunicipalityId = item.municipalityId;*/
            this.CourseForm1.Name = item.name;
            this.CourseForm1.EnglishName = item.englishName;
            this.CourseForm1.InstructorId = item.instructorId;
            this.CourseForm1.TeacherName = item.teacherName;
            this.CourseForm1.Descriptions = item.descriptions;
            this.CourseForm1.TeacherRate = item.teacherRate;
            this.CourseForm1.TaregerLevel = item.taregerLevel;
            this.CourseForm1.Rate = item.rate;
            this.CourseForm1.IsFree = item.isFree;
            this.CourseForm1.Price = item.price;



            //this.ruleForm.Password = item.userInfo.password;
            //this.ruleForm.LocationId = item.userInfo.locationId;
            this.GetallMunicipalities(item.municipalityId);
            this.state = 5;
        },


        SubmitEditForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Edit(formName);
                } else {
                    return false;
                }
            });
        },
        SubmitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Editcourse(formName);
                } else {
                    return false;
                }
            });
        },

        Edit(formName) {
            
            this.$blockUI.Start();
            this.$http.Editcenters(this.form)
                .then(response => {
                    this.resetForm(formName);
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('succsess', 'نجاح العملية', response.data);
                    this.state = 0;
                    this.GetInfo();
                    this.Clear2();

                })

                .catch((err) => {

                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                });

        },
        Editcourse(formName) {
            this.CourseForm1.InstructorId = Number(this.CourseForm1.InstructorId);
            this.CourseForm1.IsFree = Boolean(this.CourseForm1.IsFree)
            this.CourseForm1.TeacherRate = Number(this.CourseForm1.TeacherRate)
            this.CourseForm1.Rate = Number(this.CourseForm1.Rate)
            this.CourseForm1.Price = Number(this.CourseForm1.Price)
            this.CourseForm1.TaregerLevel = Number(this.CourseForm1.TaregerLevel)
            this.$blockUI.Start();
            this.$http.Editcourses(this.CourseForm1)
                .then(response => {
                    this.resetForm(formName);
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('succsess', 'نجاح العملية', response.data);
                    this.state = 6;
                    this.GetInfo1();
                   /* this.Clear3();*/

                })

                .catch((err) => {

                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                });

        },
        Clear() {
            this.ruleForm.CityId = '';
            this.ruleForm.MunicipalityId = '';
            this.ruleForm.LocationId = '';
            this.ruleForm.CenterName = '';
            this.ruleForm.LoginName = '';
            this.ruleForm.Phone = '';
            this.ruleForm.ExtraPhone = '';
            this.ruleForm.Email = '';
            this.ruleForm.Facebook = '';
            this.ruleForm.Password = '';
            this.ruleForm.ConfirmPassword = '';


        },
        Clear2() {
            //this.form.CityId = '';
            //this.form.MunicipalityId = '';
            //this.form.LocationId = '';
            this.form.LoginName = '';
            this.form.Phone = '';
            this.form.ExtraPhone = '';
            this.form.Email = '';
            this.form.Facebook = '';
            //this.form.Password = '';

        },
        Clear3() {
            this.CourseForm.AcademicSpecializationId = '';
            this.CourseForm.AcademicLevelId = '';
            this.CourseForm.SubjectId= '';
            this.CourseForm.Name= '';
            this.CourseForm.EnglishName= '';
            this.CourseForm.Descriptions= '';
            this.CourseForm.TeacherName= '';
            this.CourseForm.TeacherRate= '';
            this.CourseForm.Rate= '';
            this.CourseForm.fileBase64= '';
            this.CourseForm.fileName; '';
            this.CourseForm.IsFree= '';
            this.CourseForm.Price= '';
            this.CourseForm.CoverDescriptions = '';
            this.CourseForm.TaregerLevel = '';

        },
        Clear4() {
            this.CourseForm1.AcademicSpecializationId = '';
            this.CourseForm1.SubjectId = '';
            this.CourseForm1.Name = '';
            this.CourseForm1.EnglishName = '';
            this.CourseForm1.Descriptions = '';
            this.CourseForm1.TeacherName = '';
            this.CourseForm1.TeacherRate = '';
            this.CourseForm1.Rate = '';
            this.CourseForm1.IsFree = '';
            this.CourseForm1.Price = '';
            this.CourseForm1.TaregerLevel = '';
            this.state = 6;
        },
    }
}
